#root_div {
  position: relative;

  box-sizing: border-box;
  overflow: hidden;
}

#root_body {
  padding: 20px 30px 40px 30px;
  background: #f5f5f5;
  min-height: 90vh;
}

* {
  font-family: 'Nunito Sans', sans-serif;
}


#root_body p {
  /* font-size: 14px; */
  line-height: 24px;
  /* font-weight: 500; */
}

body {
  line-height: 24px;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url('/public/Icons/chevron-down.svg');
  background-repeat: no-repeat;
  background-position: right center;
  padding-right: 20px;
  /* Adjust this value to align the icon properly */
}

.table-search .form-control {
  padding-left: 2.375rem;
}

.table-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 24px;
  height: 24px;
  text-align: center;
  pointer-events: none;
  color: #aaa;
  background-image: url('/public/Icons/search.svg');
  background-repeat: no-repeat;
  margin: 8px;
}

table {
  border-collapse: separate;
  border-spacing: 10px;
}

thead,
th {
  font-size: clamp(8px, 2vw, 16px);
  color: #344454;
  vertical-align: middle;
  font-weight: "600" !important;
}


tbody,
td {
  font-size: clamp(8px, 2vw, 14px);
  color: #667080;
  vertical-align: middle;
}


.sidenav---sidenav---_2tBP {
  background: #ffffff !important;
  display: block;
  height: 100vh;
  position: fixed !important;
}



.sidenav---icon-bar---u1f02 {
  background: #8d8d8d !important;
}

.sidenav---sidenav-nav---3tvij>.sidenav---sidenav-navitem---uwIJ-:hover>.sidenav---navitem---9uL5T .sidenav---navicon---3gCRo,
.sidenav---sidenav-nav---3tvij>.sidenav---sidenav-navitem---uwIJ-:hover>.sidenav---navitem---9uL5T .sidenav---navtext---1AE_f,
.sidenav---sidenav-nav---3tvij>.sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u>.sidenav---navitem---9uL5T .sidenav---navicon---3gCRo,
.sidenav---sidenav-nav---3tvij>.sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u>.sidenav---navitem---9uL5T .sidenav---navtext---1AE_f {
  color: #000000 !important;
}

.sidenav---sidenav-nav---3tvij>.sidenav---sidenav-navitem---uwIJ->.sidenav---navitem---9uL5T .sidenav---navtext---1AE_f {
  color: #8d8d8d !important;
}


#party_master ul button {
  border-radius: 0px !important;
  padding: 18px;
}

#party_master .nav-link {
  color: black !important;
  font-size: clamp(16px, 2vw, 18px);
  font-weight: 600;
}

#party_master .nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  color: white !important;
  background-color: #344454 !important;
}

#party_master .nav-pills {
  border-bottom: 2px solid #f5f5f5;
}





/* button styles */
.btn-blue {
  background: #4E7DAE !important;
  color: white !important;
  border-color: #4E7DAE;
}

.btn-blue:hover {
  background: #45709d !important;
  color: white !important;
  border-color: #45709d;
}

.btn-blue:focus {
  box-shadow: 49, 132, 253;
}

.btn-blue:active {
  color: white !important;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  border-color: #45709d;
  background: #45709d;
}

.btn-blue:disabled {
  color: white !important;
  border-color: #4E7DAE;
  background: #4E7DAE;
}




.btn-light-grey {
  background: #8D8D8D !important;
  color: white !important;
  border-color: #8D8D8D;
}

.btn-light-grey:hover {
  background: #7b7979 !important;
  color: white !important;
  border-color: #7b7979;
}

.btn-light-grey:focus {
  box-shadow: 49, 132, 253;
}

.btn-light-grey:active {
  color: white !important;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  border-color: #7b7979;
  background: #7b7979;
}

.btn-light-grey:disabled {
  color: white !important;
  border-color: #8D8D8D;
  background: #8D8D8D;
}






/* button styles */
.btn-grey {
  background: #344454 !important;
  color: white !important;
  border-color: #344454;
}

.btn-grey:hover {
  background: #2f3e4d !important;
  color: white !important;
  border-color: #2f3e4d;
}

.btn-grey:focus {
  box-shadow: 49, 132, 253;
}

.btn-grey:active {
  color: white !important;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  border-color: #2f3e4d;
  background: #2f3e4d;
}

.btn-grey:disabled {
  color: white !important;
  border-color: #344454;
  background: #344454;
}

.btn-lightblue {
  background: #ABBCCC !important;
  color: #2A3643 !important;
  border-color: #ABBCCC;
}

.btn-lightblue:hover {
  background: #87a4be !important;
  color: #2A3643 !important;
  border-color: #87a4be;
}

.btn-lightblue:focus {
  box-shadow: 49, 132, 253;
}

.btn-lightblue:active {
  color: #2A3643 !important;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  border-color: #87a4be;
  background: #87a4be;
}

.btn-lightblue:disabled {
  color: #2A3643 !important;
  border-color: #ABBCCC;
  background: #ABBCCC;
}

.btn-darkblue {
  background: #4E7DAE !important;
  color: white !important;
  border-color: #4E7DAE;
}

.btn-darkblue:hover {
  background: #4778ac !important;
  color: white !important;
  border-color: #4778ac;
}

.btn-darkblue:focus {
  box-shadow: 49, 132, 253;
}

.btn-darkblue:active {
  color: white !important;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  border-color: #4778ac;
  background: #4778ac;
}

.btn-darkblue:disabled {
  color: white !important;
  border-color: #4E7DAE;
  background: #4E7DAE;
}




/* button styles */
.btn-yellow {
  background: #EEAE2D !important;
  color: black !important;
  border-color: #EEAE2D;
  border-radius: 0px;
}

.btn-yellow:hover {
  background: #dfa32c !important;
  color: black !important;
  border-color: #dfa32c;
}

.btn-yellow:focus {
  box-shadow: 49, 132, 253;
}

.btn-yellow:active {
  color: #344454 !important;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  border-color: #dfa32c;
  background: #dfa32c;
}

.btn-yellow:disabled {
  color: #344454 !important;
  border-color: #EEAE2D;
  background: #EEAE2D;
}

.btn-yellow-wt {
  background: #EEAE2D !important;
  color: white !important;
  border-color: #EEAE2D;
  border-radius: 0px;
}

.btn-yellow-wt:hover {
  background: #dfa32c !important;
  color: white !important;
  border-color: #dfa32c;
}

.btn-yellow-wt:focus {
  box-shadow: 49, 132, 253;
}

.btn-yellow-wt:active {
  color: #344454 !important;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  border-color: #dfa32c;
  background: #dfa32c;
}

.btn-yellow-wt:disabled {
  color: #344454 !important;
  border-color: #EEAE2D;
  background: #EEAE2D;
}

.btn-black {
  background: #0f0f0f !important;
  color: rgb(238, 232, 232) !important;
  border-color: #161616;
  border-radius: 0px;
}







input.control-border {
  border: 1px solid #ABBCCC;
  border-radius: 0px;
  line-height: 24px;
}

textarea.control-border {
  border: 1px solid #ABBCCC;
  border-radius: 0px;
  line-height: 24px;
}

.lbl-style {
  font-size: 15px;
  color: #344454;
}


::-webkit-input-placeholder {
  color: #8D8D8D;
  font-size: 16px;
}




.editIcon {
  background-color: #2A3643;
  border-radius: 15%;
  display: inline-block;
  padding: 4px 6px;
  cursor: pointer;
}

.editIcon-sm {
  background-color: #2A3643;
  border-radius: 15%;
  display: inline-block;
  padding: 2px 6px;
  cursor: pointer;
}



.deleteIcon {
  background-color: #FF134E;
  border-radius: 15%;
  display: inline-block;
  padding: 4px 6px;
  cursor: pointer;
}

.deleteIcon-sm {
  background-color: #FF134E;
  border-radius: 15%;
  display: inline-block;
  padding: 2px 6px;
  cursor: pointer;
}

.downloadIcon {
  background-color: #EEAE2D;
  border-radius: 15%;
  display: inline-block;
  padding: 4px 6px;
  cursor: pointer;
}

.bg-grey {
  background-color: #2A3643 !important;
  color: white !important;
}

.pdrIcon {
  background-color: #ABBCCC;
  border-radius: 15%;
  display: inline-block;
  padding: 4px 6px;
  cursor: pointer;
}

.spanStyle {
  background: #EEAE2D;
  padding: 1px;
}

.cursor_pointer {
  cursor: pointer;
}

.bg-lightgrey {
  background: rgba(198, 198, 199, 0.205);
}


/* Style for Page Heading */
.page-heading {
  font-size: clamp(16px, 2vw, 30px);
  font-weight: "600" !important;
  line-height: 24px;

  @media (max-width: 992px) {

    font-size: clamp(16px, 2vw, 24px);
  }

  @media (max-width: 576px) {

    font-size: clamp(16px, 2vw, 16px);
  }
}

.label-text {
  font-size: clamp(16px, 2vw, 18px);
  font-weight: 700;
  line-height: 24px;
}

/* Style for Page Heading */

/* Style for Bold Header */
.button-text {
  font-size: clamp(8px, 2vw, 18px);
  font-weight: 500;
  line-height: 24px;

  @media (max-width: 992px) {
    /* Tablet */
    font-size: clamp(8px, 2vw, 12px);
  }

  @media (max-width: 576px) {
    /* Mobile */
    font-size: clamp(8px, 2vw, 8px);
  }
}

/* Style for Bold Header */

/* Style for tabs pills text */
label.txt-control {
  font-size: clamp(8px, 2vw, 16px);
  font-weight: 500;
  line-height: 24px;
}

.txt-control {
  font-size: clamp(8px, 2vw, 16px);
  font-weight: 500;
  line-height: 24px;
}

.entriesPerPage {
  font-size: 16px;
}

/* Style for tabs pills text */

.nc .filter,
input::placeholder,
textarea::placeholder {
  font-size: 14px !important;
}




table th {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
  min-width: 100px !important;
}


table thead tr th {
  font-size: 14px;
}

table tr th:first-child {
  padding-left: 16px !important;
}

table tr td:first-child {
  padding-left: 16px !important;
}

table td {
  font-size: 14px !important;
}

.sidenav---sidenav-toggle---1KRjR {
  height: 56px !important;
}




#root_div_main {
  transition: margin-left .5s;
  position: relative;
  background: #f5f5f5;
  box-sizing: border-box;
  overflow: hidden;
}


/* below class is used for styling the filter layout sidebar */
.customsidebar {
  height: 100%;
  width: 0;
  max-width: 320px !important;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #ABBCCC;
  overflow-x: hidden;
  transition: 0.5s ease-in-out;
}

.customsidebar .content {
  overflow-y: auto;
  max-height: 90vh;
  min-height: 90vh;
  margin-top: 40px;
  padding: 18px;
}

.customsidebar #basic-typeahead-single {
  transform: unset !important;
  inset: unset !important;
}

.rbt-menu {
  position: absolute !important;
  inset: 0px auto auto 0px !important;
  display: block !important;
  max-height: 300px !important;
  overflow: auto !important;
  transform: translate(0px, 38px) !important;
  width: 288.328px !important;
}


/* close button for sidebar layout */
.customsidebar .closebtn {
  text-decoration: none;
  font-size: 25px;
  color: #000000;
  display: block;
  transition: 0.3s;
  position: absolute;
  top: 0;
  right: 18px;
  font-size: 36px;
  cursor: pointer;
}





/* datepicker dropdown doesnt properly display inside sidebar for handling that below styles are required */
#root_div_main .fromDate .react-datepicker-popper {
  position: absolute;
  inset: unset !important;
  transform: unset !important;
  z-index: 9999 !important;
}

#root_div_main .fromDate .react-datepicker__triangle {
  position: absolute !important;
  left: 15% !important;
  transform: translate(0px, 0px) !important;
}

#root_div_main .toDate .react-datepicker-popper {
  position: absolute;
  inset: unset !important;
  transform: unset !important;
  z-index: 9999 !important;
}

#root_div_main .toDate .react-datepicker__triangle {
  position: absolute !important;
  left: 15% !important;
  transform: translate(0px, 0px) !important;
}


.nc_detail_label {
  color: #666666;
  font-size: 14px;
}

.form_label {
  color: #000000;
  font-size: 14px;
}

.react-datepicker-wrapper {
  width: 100%;
}

.err-msg {
  font-size: 14px;
}





/* Navbar styles */


.navbar {
  background: white !important;
  padding: 5px 0px 0px 0px !important;
  font-size: 16px;
  font-weight: 800;
}

#navbar-light-example .dropdown-toggle::after {
  display: none !important;
}


#main_link_nav .nav-link {
  letter-spacing: 0.5px;
  color: #2a3643;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
}

/* making selected link active in navbar */
.navbar-nav .nav-link.active {
  color: #ff134e !important;
  border-bottom: 3px solid #ff134e;
}

.master_screen #ccard .card .card-title {
  color: #2a3643;
  font-size: 16px;
  display: inline-block;
  border-bottom: 2px solid #fff;
}

.master_screen #ccard .card:hover .card-title {
  color: #ff134e !important;
  display: inline-block;
  border-bottom: 2px solid #ff134e;
}




/* reducing border radius of all input type in project */
.form-control-sm {
  border-radius: 2px !important;
}

.form-control {
  border-radius: 2px !important;
}


/* styles used for pagination */
.pagination .active>.page-link {
  background-color: #2a3643 !important;
  border-color: #2a3643 !important;
  color: white !important;
}

.pagination .page-link {
  color: #2a3643 !important;
}


/* modal close button image */
.btn-close {
  --bs-btn-close-bg: url("./Assets/Images/modalCross1.png") !important;
}

#navbar-light-example .dropdown-toggle::after {
  display: none !important;
}


svg {
  margin-bottom: 2px !important;
}

.fw600 {
  font-weight: 600 !important;
}

.fw800 {
  font-weight: 800 !important;
}

.modalHeader {
  background: #2A3643 !important;
  color: white !important
}


.table-grey {
  --bs-table-color: #fff;
  --bs-table-bg: #2A3643;
  --bs-table-border-color: #373b3e;
  --bs-table-striped-bg: #2c3034;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #373b3e;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #323539;
  --bs-table-hover-color: #fff;
  color: white;
}





.login_page {
  overflow-x: hidden;
  /* padding: 20px; */
  height: 100vh;
}

.div_center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.text_gold {
  color: #EEAE2D;
}

.form_div {
  margin-top: 100px;
}

.login_page #login_img {
  height: 95vh;
  border-radius: 20px;
  border: 1px solid #DDDBF1;
}

.pink_rectangle {
  height: 6px;
  width: 130px;
  background: #FF134E;
}

.text_over_img {
  top: 10%;
  padding-left: 20px;
  padding-right: 20px;
  max-width: 400px;
  color: black;
  position: absolute;
  font-size: 1.4em;
}



@media (max-width: 768px) {

  /* Styles for smaller screens */
  .login_page {
    overflow-x: hidden;
    height: 100%;
  }

  .form_div {
    margin-top: 10px;
  }

  .login_page #login_img {
    height: 100%;
    margin-top: 15px;
  }

  .text_over_img {
    top: 10%;
    padding-left: 10px;
    padding-right: 10px;
    /* transform: translate(-50%, -50%); */
    max-width: 400px;
    color: white;
    position: absolute;
    font-size: 1.4em;
  }
}

.tool-container {
  position: relative;
  max-width: 80%;
  margin: 0 auto;
  aspect-ratio: 1 / 1;
  /* background: url('./Assets/Diagrams/tool1.png') no-repeat center/contain; */
}

.spec {
  position: absolute;
}

.spec input {
  width: 50px;
  font-size: 16px;
  margin-right: 10px;
}

.filterLabel{
  font-weight: 900;
}